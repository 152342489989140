import React from 'react';
import './Footer.scss';
import madeWithBulma from '../../../images/made-with-bulma.png';

const Footer = () => (
  <footer
    id="contact"
    className="hero section is-dark"
    data-aos="fade-in"
  >
    <div className="hero-foot">
      <div className="copyright has-text-centered has-text-light">
        <p>Copyright © {new Date().getFullYear()}</p>
        <p>
          Developed by <a className="source" href="www.linkedin.com/in/bakary-tounkara-3b6b5b85">Bakary Tounkara</a>
        </p>
        <p>
          Source on{' '}
          <a
            className="source"
            href="https://github.com/btounkara/lamedunpirate"
          >
            Github
          </a>
        </p>
        <p>
          <a href="https://bulma.io">
            <img
              src={madeWithBulma}
              alt="Made with Bulma"
              width="163"
              height="31"
            />
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
